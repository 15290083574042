// main JS
var FE = {
    location: window.location,

    Lightbox: {
        init: function() {
            $('a.lightbox').magnificPopup({
                type:'image',
                gallery:{enabled:true}
            });
        }
    },

    MobileMenu: {
        init: function() {
            FE.MobileMenu.indicateSubMenu();
            $('#burger-icon').on('click', function(event) {
               event.preventDefault();
               $('.nav-main').slideToggle();
               $('.nav-main li.open ul').hide();
               $('.nav-main li').removeClass('open');
            });
        },
        indicateSubMenu: function() {
            $('.nav-main > ul > li').each(function() {
                if ( $(this).find('ul').length ) {
                    $(this).prepend('<span>&gt;</span>');
                }
                $(this).find('span').click(function() {
                    $(this).siblings('ul').slideToggle();
                    $(this).parents('li').toggleClass('open');
                });
            });
        }
    },

    YouTube: {
        youtubeContainer: null,
        youtubeId: null,

        init: function () {
            FE.YouTube.observeYouTubeOpen();
        },
        observeYouTubeOpen: function () {
            var elements = document.querySelectorAll('.youtube-embed');
            Array.prototype.forEach.call(elements, function(el, i) {

                el.addEventListener('click', function (e) {

                    e.preventDefault();

                    var urlParts = this.href;
                    var youTubeId = urlParts.split('v=')[1];

                    FE.YouTube.youtubeId = youTubeId;
                    FE.YouTube.youtubeContainer = this.parentNode;

                    // check if consent is already there
                    if ( document.cookie.match(new RegExp('youtube_consent=([^;]+)')) ) {
                        FE.YouTube.loadYouTubeVideo(FE.YouTube.youtubeContainer, FE.YouTube.youtubeId);
                    } else {
                        FE.YouTube.getUserConsent();
                    }

                    // Optionally save decision cookie


                })
            });
        },
        getUserConsent: function () {

            var youTubeConsentHtml = 'An dieser Stelle laden wir ein externes Video von YouTube.<br>Um dies zu sehen musst Du den YouTube <a href="https://policies.google.com/technologies/cookies?hl=de" target="_blank">Datenschutzbedingungen</a> zustimmen.';
                youTubeConsentHtml += '<form><div class="checkbox"><input type="checkbox" id="remember_yt" name="remember_yt" value="ja" /> <label for="remember_yt">Meine Entscheidung in Form eines Cookies merken. Das Cookie wird nach Ende der Browser Session gelöscht.</label></div><div class="btn-group"><button class="btn-yt-confirm">Geht klar</button><button class="btn-yt-deny">möchte ich nicht</button></div></form>';


            // Open YT consent
            $.magnificPopup.open({
                items: {
                    src: '<div class="youtube-consent">' + youTubeConsentHtml + '</div>',
                    type: 'inline'
                }
            });

            $('.btn-yt-confirm').on('click', function () {

                // check if we need to store user decision
                console.log(document.querySelector('#remember_yt'));
                if ( document.querySelector('#remember_yt').checked ) {
                    FE.YouTube.storeYouTubeConsentInCookie();
                }
                FE.YouTube.loadYouTubeVideo(FE.YouTube.youtubeContainer, FE.YouTube.youtubeId);
                $.magnificPopup.close();
            });

            $('.btn-yt-deny').on('click', function (){
                $.magnificPopup.close();
            });

        },
        loadYouTubeVideo: function (youTubeContainer, youTubeId) {
            youTubeContainer.innerHTML = FE.YouTube.getYouTubeEmbedCode(youTubeId);
        },
        getYouTubeEmbedCode: function (youTubeId) {
            return '<div class="video"><iframe width="100%" height="315" src="https://www.youtube-nocookie.com/embed/' + youTubeId + '" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>';
        },
        storeYouTubeConsentInCookie: function () {
            document.cookie = 'youtube_consent=1;path=/;domain=kidnetting.de';
        }

    }

};

// init custom functions
(function ($, window, document, undefined) {

    $(function () {

        FE.MobileMenu.init();
        FE.Lightbox.init();

        if ( $('.youtube-embed').length ) {
            FE.YouTube.init();
        }

    });

}(jQuery, window, document));
